<template>
  <woot-button
    :size="size"
    variant="clear"
    color-scheme="secondary"
    class="bg-color icon-container"
    @click="onMenuItemClick"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M1.83333 12H10.1667C10.2933 12 10.4153 12.0482 10.5079 12.1346C10.6004 12.2211 10.6567 12.3395 10.6653 12.4659C10.674 12.5923 10.6343 12.7173 10.5544 12.8155C10.4744 12.9138 10.3602 12.9781 10.2347 12.9953L10.1667 13H1.83333C1.70575 13.0013 1.58249 12.9538 1.48881 12.8671C1.39513 12.7805 1.3381 12.6614 1.32941 12.5341C1.32072 12.4068 1.36102 12.281 1.44206 12.1824C1.52311 12.0839 1.63876 12.02 1.76533 12.004L1.83333 12ZM1.83333 7.66666H14.1667C14.2933 7.6667 14.4153 7.71482 14.5079 7.80131C14.6004 7.88779 14.6567 8.00619 14.6653 8.13258C14.674 8.25897 14.6343 8.38392 14.5544 8.48219C14.4744 8.58046 14.3602 8.64473 14.2347 8.66199L14.1667 8.66666H1.83333C1.70665 8.66662 1.58471 8.6185 1.49214 8.53201C1.39957 8.44553 1.34328 8.32713 1.33465 8.20074C1.32601 8.07435 1.36568 7.9494 1.44562 7.85113C1.52557 7.75286 1.63983 7.68859 1.76533 7.67133L1.83333 7.66666ZM1.83333 3.33533H12.1667C12.2933 3.33537 12.4153 3.38349 12.5079 3.46998C12.6004 3.55646 12.6567 3.67486 12.6653 3.80125C12.674 3.92763 12.6343 4.05259 12.5544 4.15086C12.4744 4.24913 12.3602 4.31339 12.2347 4.33066L12.1667 4.33533H1.83333C1.70665 4.33529 1.58471 4.28716 1.49214 4.20068C1.39957 4.11419 1.34328 3.9958 1.33465 3.86941C1.32601 3.74302 1.36568 3.61807 1.44562 3.51979C1.52557 3.42152 1.63983 3.35726 1.76533 3.33999L1.83333 3.33533Z"
        fill="#3CBEB6"
      />
    </svg>
  </woot-button>
</template>

<script>
import { BUS_EVENTS } from 'shared/constants/busEvents';

export default {
  props: {
    size: {
      type: String,
      default: 'small',
    },
  },
  methods: {
    onMenuItemClick() {
      this.$emitter.emit(BUS_EVENTS.TOGGLE_SIDEMENU);
    },
  },
};
</script>

<style scoped>
.bg-color {
  color: #3cbeb6;
}

.icon-container {
  flex-shrink: 0;
  border-radius: 5px;
  background: var(--BRANCO, #fff);
  display: flex;
  align-items: center;
  justify-content: center;
  left: 4px;
  top: 4px;
}
</style>
