var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"button",class:_vm.buttonClasses,attrs:{"type":_vm.type,"disabled":_vm.isDisabled || _vm.isLoading},on:{"click":_vm.handleClick}},[(_vm.isLoading)?_c('spinner',{attrs:{"size":"small","color-scheme":_vm.showDarkSpinner ? 'dark' : ''}}):(_vm.icon || _vm.emoji)?_c('emoji-or-icon',{staticClass:"icon",attrs:{"emoji":_vm.emoji,"icon":_vm.icon,"icon-size":_vm.iconSize}}):_vm._e(),_vm._v(" "),(_vm.$slots.default)?_c('span',{class:[
      _vm.disableTextAlignment ? '' : 'button__content',
      {
        'text-left rtl:text-right':
          _vm.size !== 'expanded' && !_vm.disableTextAlignment,
      },
    ]},[_vm._t("default")],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }